// google analytics
import ReactGA from 'react-ga';
import * as createHistory from "history";

ReactGA.initialize('UA-156453259-1');
ReactGA.pageview(window.location.pathname);


const history = createHistory.createBrowserHistory();
history.listen((location) => {
  ReactGA.set({
    page: location.pathname,
  });
  ReactGA.pageview(location.pathname);
});

export default history ;