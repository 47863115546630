// React core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Footer } from 'components';

import { multilanguage, loadLanguages } from 'redux-multilanguage';

import iziToast from 'izitoast';
import { TOAST, ACCOUNT_CONTRACT } from 'components/global';

import { LoginWallet, LogoutWallet, IdentityWallet, pushAction, pushDoubleAction } from 'services/ApiService'

import sending from 'assets/images/sending.svg'
// const SUCCESS = 200
const MIN_EOS = 500000

const TIME_OUT = 700

class RegisterProxy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proxyAccount: '',   //proxy account that you want to register
            rewardProxy: '',    //Rewards % to Voters
            feeProxy: '',       //FeeProxy
            refProxy: '',       //referral proxy

            IsAutoVote: false,

            isSendingActionList: {
                regProxy: false,
            }
        }
        this.inputTimeOut = 0
    }

    componentDidMount = () => {
        this.loadLanguages();
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    updateProxyAccount = (proxyAccount) => {
        this.setState({
            proxyAccount: proxyAccount.target.value ? proxyAccount.target.value : '',
        })
    }

    updateRewardProxy = (rewardProxy) => {
        this.setState({
            rewardProxy: rewardProxy.target.value ? rewardProxy.target.value : 0,
        })

        const checkRewardProxy = rewardProxy.target.value

        if(this.inputTimeOut){
            clearTimeout(this.inputTimeOut);
        }
        this.inputTimeOut = setTimeout(() => {
            if(checkRewardProxy > 100){
                this.setState({
                    rewardProxy: 100
                })
            }

        }, TIME_OUT);
    }

    updateFeeProxy = (feeProxy) => {
        this.setState({
            feeProxy: feeProxy.target.value ? feeProxy.target.value : 0,
        })

        const checkFeeProxy = feeProxy.target.value

        if(this.inputTimeOut){
            clearTimeout(this.inputTimeOut);
        }
        this.inputTimeOut = setTimeout(() => {
            if(checkFeeProxy > 100){
                this.setState({
                    feeProxy: 100
                })
            }

        }, TIME_OUT);
    }

    updateRefProxy = (refProxy) => {
        this.setState({
            refProxy: refProxy.target.value ? refProxy.target.value : 0,
        })

        const checkRefProxy = refProxy.target.value

        if(this.inputTimeOut){
            clearTimeout(this.inputTimeOut);
        }
        this.inputTimeOut = setTimeout(() => {
            if(checkRefProxy > 100){
                this.setState({
                    refProxy: 100
                })
            }

        }, TIME_OUT);
    }

    updateStatusActionList = (action, isSending) => {
        let actionList = this.state.isSendingActionList
        
        actionList[`${action}`] = isSending
        this.setState({
            isSendingActionList: actionList
        })
    }

    handleRegProxy = async () => {
        if(this.state.proxyAccount === ''){
            iziToast.show({
                message: this.props.strings["InvalidProxyAccount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(this.state.rewardProxy === ''){
            iziToast.show({
                message: this.props.strings["InvalidPer"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(this.state.feeProxy === ''){
            iziToast.show({
                message: this.props.strings["InvalidFee"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(this.state.refProxy === ''){
            iziToast.show({
                message: this.props.strings["InvalidRef"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if(!this.props.wallet){
            iziToast.show({
                message: this.props.strings["PlsLoginScatter"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });

        }else {
                    // this.setState({
        //   IsLoading: true,
        // })
        if (this.props.wallet && this.props.wallet.data) {
            //The cost is 5 EOS to register a rewards proxy or free if you have over 500,000 EOS assigned to the proxy already
            this.updateStatusActionList('regProxy', true)
            //check value of proxy
            try{
                const proxyInfo = await fetch(`https://htudinvi3m.execute-api.us-east-2.amazonaws.com/genpool/${this.state.proxyAccount}?output=json`, {
                    method: 'GET'
                  })

                // console.log('tam ', proxyInfo)
                if(proxyInfo){
                    const proxyInfos = await proxyInfo.json()
                    // console.log('tam check proxy', proxyInfos)
                    if(proxyInfos && proxyInfos.status === 'success' && proxyInfos.proxy){
                        const amountProxy = proxyInfos.proxy.proxied_vote_eos

                        const actor = this.props.wallet.data.account_name
                        const permission = this.props.wallet.data.permissions[0].perm_name
                        const wallet = this.props.wallet.wallet

                        const account = ACCOUNT_CONTRACT
                        const action = 'regproxy'

                        const proxy = this.state.proxyAccount
                        const reward_percent = parseFloat(this.state.rewardProxy / 100)
                        const proxy_fee_percent = parseFloat(this.state.feeProxy / 100)
                        const referral_percent = parseFloat(this.state.refProxy / 100)

                        const data = {
                            proxy: proxy,
                            reward_percent: reward_percent,
                            proxy_fee_percent: proxy_fee_percent,
                            target_apr: 0,
                            referral_percent: referral_percent,
                            transfer_reward: false,
                        }
                        // console.log('tam check amount', amountProxy )

                        if(amountProxy > MIN_EOS){
                            const regAsProxy = await pushAction(wallet, account, action, actor, data, permission)
                            if(regAsProxy){
                                iziToast.show({
                                    message: this.props.strings["RegOk"],
                                    progressBar: false,
                                    timeout: TOAST.TimeOut,
                                    backgroundColor: TOAST.SucessColor,
                                    messageSize: TOAST.MessageSize,
                                    position: TOAST.Position,
                                    messageColor: TOAST.MessageColor,
                                });
                            }
                            // console.log('tam regAsProxy higer5000', regAsProxy)

                        }else{
                            const account2 = 'eosio.token'
                            const action2 = 'transfer'

                            const data2 = {
                                from: actor,
                                to: ACCOUNT_CONTRACT,
                                quantity: '5.0000 EOS',
                                memo: this.state.proxyAccount,
                            }

                            const regAsProxy = await pushDoubleAction(wallet, account, account2, action, action2, actor, data, data2, permission)
                            // console.log('tam regAsProxy lower', regAsProxy)
                            if(regAsProxy && regAsProxy.transaction_id){
                                this.setState({
                                    proxyAccount: '',
                                    rewardProxy: '',
                                    feeProxy: '',
                                    refProxy: '',
                                })
                                iziToast.show({
                                    message: this.props.strings["RegOk"],
                                    progressBar: false,
                                    timeout: TOAST.TimeOut,
                                    backgroundColor: TOAST.SucessColor,
                                    messageSize: TOAST.MessageSize,
                                    position: TOAST.Position,
                                    messageColor: TOAST.MessageColor,
                                });
                            }

                        }
                    }else{
                        iziToast.show({
                            message: this.props.strings["FailAmount"],
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }else{
                    iziToast.show({
                        message: this.props.strings["FailAmount"],
                        progressBar: false,
                        timeout: TOAST.TimeOut,
                        backgroundColor: TOAST.WarningColor,
                        messageSize: TOAST.MessageSize,
                        position: TOAST.Position,
                        messageColor: TOAST.MessageColor,
                    });
                }
            }catch(error){
                iziToast.show({
                    message: this.props.strings["FailAmount"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
            this.updateStatusActionList('regProxy', false)
        } else {
            iziToast.show({
                message: this.props.strings["PlsLoginScatter"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }
        // this.setState({
        //   IsLoading: false,
        // })

        }
    }

    updateAutoVote = async () => {
        if(this.state.proxyAccount === ''){
            iziToast.show({
                message: this.props.strings["InvalidProxyAccount"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }else if (this.props.wallet && this.props.wallet.data) {
            const actor = this.props.wallet.data.account_name
            const permission = this.props.wallet.data.permissions[0].perm_name
            const wallet = this.props.wallet.wallet

            const account = 'eosio'

            const action = 'updateauth'
            const action2 = 'linkauth'

            const auth = {
                threshold: 1,
                keys: [],
                waits: [],
                accounts: [{
                        weight: 1,
                        permission: {
                            actor: 'genereospool',
                            permission: 'eosio.code'
                        }
                    }]
                }

            const data = {
                account: this.props.wallet.data.account_name,
                auth: auth,
                parent: 'active',
                permission: 'vote',
            }

            const data2 = {
                account: this.props.wallet.data.account_name,
                code: 'eosio',
                requirement: 'vote',
                type: 'voteproducer',
            }

            const regAuth = await pushDoubleAction(wallet, account, account, action, action2, actor, data, data2, permission)
            // console.log('tam regAsProxy lower', regAsProxy)
            if(regAuth && regAuth.transaction_id){
                iziToast.show({
                    message: this.props.strings["RegOk"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
                this.setState({
                    IsAutoVote: true,
                })
            }

        }else{
            iziToast.show({
                message: this.props.strings["PlsLoginScatter"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }
    }

    render() {
        const { strings } = this.props
        return (
            <div className="body">
                <div className="main-container">
                    <div id="Hero" className="hero">
                        <div>
                            <div className="hero-heading">GENPOOL</div>
                            <div className="hero-subheading">THE&nbsp;FIRST&nbsp;EOS&nbsp;VOTER&nbsp;EXCHANGE</div>
                        </div>
                    </div>
                </div>

                <div className="section-6">
                    <div className="container-3 w-container">
                        <div className="div-block-24">
                            <div className="div-block-14-copy">
                                <div className="listdiv">
                                    <h1 className="listheading _22-copy">{strings["RewardsProxy"]}</h1>
                                    <h2 className="listproxyheading sub2">{strings["ProxyAccount"]}</h2>
                                    <div className="form-block-2 w-form">
                                        <form id="email-form" name="email-form" data-name="Email Form">
                                            <input type="text" className="text-field w-input" maxLength={256} placeholder={this.props.strings["EosAccountName"]} id="name-proxy-account"
                                                value={this.state.proxyAccount || '' } onChange={this.updateProxyAccount} onInput={this.maxLengthCheck}/>
                                            <div className="text-block-13 info">{strings["InforReg1"]} <a href="https://eostoolkit.io/vote/createproxy" className="link-10">{strings["Here"]}</a> {strings["Register"]} </div>

                                            <h2 className="fundtittle sub2">{strings["Header2"]}</h2>
                                            <input type="number" className="text-field w-input" maxLength={3} placeholder="0-98% " id="name-reward" min="0" max="100"
                                                value={this.state.rewardProxy || ''} onChange={this.updateRewardProxy} onInput={this.maxLengthCheck}/>
                                            <div className="text-block-13 info">{strings["InforReg2"]} <a href="docs.html" className="link-9">{strings["FAQ"]}</a> {strings["MoreInfo"]}</div>

                                            <h2 className="heading-5-copy-copy sub2">{strings["ProxyFeeReg"]}</h2>
                                            <input type="number" className="text-field w-input" maxLength={3} placeholder="0-98% " id="name-fee"  min="0" max="100"
                                                value={this.state.feeProxy || ''} onChange={this.updateFeeProxy} onInput={this.maxLengthCheck}/>
                                            <div className="text-block-13 info">{strings["EarnEOS"]}</div>
                                            <h2 className="heading-5-copy-copy sub2">{strings["ProxyRef"]}</h2>
                                            <input type="number" className="text-field w-input" maxLength={3} placeholder="0-98% " id="name-ref"  min="0" max="100"
                                                value={this.state.refProxy || ''} onChange={this.updateRefProxy} onInput={this.maxLengthCheck}/>
                                            <div className="text-block-13 info">{strings["RefEOS"]}</div>
                                        </form>
                                        <div className="w-form-done">
                                            <div>{strings["FormDoneReg"]}</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>{strings["OopsReg"]}</div>
                                        </div>
                                    </div>

                                    <div className="label-information">
                                        <div className="toggle-group" >
                                            {/*<span className="tooltiptext">{strings["help_tooltip_reg"]}</span>*/}
                                            <input type="checkbox" id="on-off-switch" value={this.state.IsAutoVote} checked={`${this.state.IsAutoVote ? 'checked' : ''}`} />
                                            <label htmlFor="on-off-switch" className="regProxy-label"> {strings["auto_vote"]} </label>
                                            <div className="onoffswitch pull-right" aria-hidden="true" onClick={this.updateAutoVote}> 
                                                <div className="onoffswitch-label">
                                                    <div className="onoffswitch-inner" />
                                                    <div className="onoffswitch-switch" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-block-13-copy-copy warnnig">
                                        {strings["help_tooltip"]}
                                    </div>

                                    <div className="button4 w-button-edit regProxyBtn-1" onClick={this.handleRegProxy}>
                                    {
                                        this.state.isSendingActionList.regProxy ? 
                                            <img src={sending} alt="" className="image-refProxy-1"/>
                                                : <div>{strings["Submit"]}</div>
                                    }
                                    </div>
                                    <div className="text-block-13-copy-copy info">{strings["Note"]}<a href="https://t.me/generEOS" className="link-12">{strings["TelegramReg"]}</a> {strings["NotificationsReg"]}</div>
                                </div>
                            </div>
                            <div className="div-block-23">
                                <h1 className="heading-7">{strings["Built"]}</h1>
                                <p className="paragraph-4">{strings["InforReg3"]}<a href="mailto:contact@genereos.io?subject=Custom%20Proxy%20build" className="link-8">{strings["ContactReg"]}</a><a href="mailto:contact@genereos.io?subject=Custom%20Proxy" className="link-7"> </a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        wallet: state.wallet,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        indetify: () => {
            IdentityWallet(dispatch);
        },
        login: () => {
            LoginWallet(dispatch);
        },
        logout: () => {
            LogoutWallet(dispatch);
        }
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(RegisterProxy));
