import { combineReducers } from 'redux';
import {
  LOGIN,
  LOGOUT,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  RESET,
} from './Action';

import {createMultilanguageReducer} from 'redux-multilanguage';

function wallet(state = null, action) {
  switch (action.type) {
    case LOGIN:
      return action;
    case LOGOUT:
      return null;
    default:
      return state;
  }
}

function message(state = null, action) {
  switch (action.type) {
    case LOGIN_ERROR:
      return action;
    case LOGOUT_ERROR:
      return action;
    case RESET:
      return null;
    default:
      return state;
  }
}

const Reducer = combineReducers({
  wallet,
  message,
  multilanguage: createMultilanguageReducer({currentLanguageCode: 'en'}),
});

export default Reducer;
