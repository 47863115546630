// React core
import React, { Component } from 'react';
import './Spin.css';

class Spin extends Component {
    render() {
        return (
            <div className="div-block-spin">
                <div id="loader-wrapper">
                    <div id="loader"></div>
                </div>
            </div>
        );
    }
}

// Export a redux connected component
export default (Spin);
