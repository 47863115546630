import React, { Component } from 'react';
import { connect } from 'react-redux';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import OutsideClickHandler from 'react-outside-click-handler';
import { ESC } from 'components/global';

import Close_Window from 'assets/images/close.svg';
import Connecting from 'assets/images/default.gif';

const SCATTER = 'scatter'
const TOKENPOCKET = 'TokenPocket'
const MEETONE = 'meetone_provider'
const LYNX = 'EOS Lynx'
const SIMPLEOS = 'simpleos'
const ANCHOR = 'anchor-link'
const NO_WALLET = -1

class WalletList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsConnectWallet: NO_WALLET,
        }
    }

    componentDidMount = () => {
        this.loadLanguages();
        document.addEventListener("keydown", this.closePopUp, false);
        // console.log('tam props', this.props)
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.closePopUp, false);
        this.setState({
            IsConnectWallet: NO_WALLET,
        })
    }

    closePopUp = (key) => {
        if (key.which === ESC) {
            this.closeLayout()
        }
    }

    closeLayout = () => {
        this.props.onClosePopUp()
    }

    SelectWallet = (index, wallet) => () => {
        this.setState({
            IsConnectWallet: index,
        })
        this.props.onSelectWallet(wallet)
    }

    LogoIcon = (id) => {
        if(id === SCATTER){
            return 'Scatter.png'

        } if(id ===TOKENPOCKET){
            return 'TokenPocket.png'

        }else if(id === MEETONE){
            return 'meetone.png'

        }else if(id === LYNX){
            return 'Lynx.png'

        }else if(id === SIMPLEOS){
            return 'simpleos.png'

        }else if(id === ANCHOR){
            return 'anchor-link.png'
        }
        else{
            return 'default.gif'
        }
    }

    render() {
        const listwallet = this.props.listWall ? this.props.listWall.map((wallet, index) => {
            return (
                <div key={index} className="pop-up-block-4" onClick={this.SelectWallet(index, wallet)}>
                {
                    (this.state.IsConnectWallet === index) ? <img src={Connecting} width={40} height={40} alt=''/> :
                    <img src={require(`assets/images/${this.LogoIcon(wallet.id)}`)} width={40} height={40} alt=''/>
                }
                    <div className="pop-up-block-5">{wallet.meta.name}</div>
                </div>
            )
        }) : null

        return (
            <div className="pop-up">
                <div className="pop-up-wrap" >
                    <OutsideClickHandler onOutsideClick={this.closeLayout}>
                        <div className="pop-up-block-1" >
                            <div className="pop-up-block-2" onClick={this.closeLayout}><img src={Close_Window} width={25} alt="" /></div>
                        </div>
                        <div className="pop-up-block-10" >
                            <div className="pop-up-block-11" >{this.props.strings["ConnectWallet"]}</div>
                        </div>
                        <div className="pop-up-block-3">
                            {listwallet}
                        </div>
                    </OutsideClickHandler>
                </div>
            </div>
        );
    }
}

// Export a redux connected component
export default connect()(multilanguage(WalletList))
