export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const RESET = 'RESET';

export const login = (wallet, data) => ({
  type: LOGIN, wallet, data
});
export const logout = () => ({
  type: LOGOUT,
});
export const loginError = (error) => ({
  type: LOGIN_ERROR, error
});
export const logoutError = (error) => ({
  type: LOGOUT_ERROR, error
});
export const resetMessage = () => ({
  type: RESET,
})
