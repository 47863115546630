// React core
import React, { Component } from 'react';
import { Router , Route, Switch } from "react-router-dom";

import "izitoast/dist/css/iziToast.css";

import {
  ErrorPage,
  MainPage,
  DashBoard,
  ProxyInternal,
  RegisterProxy,
  BpInternal,
  Docs,
  Navigation
} from 'components';
import history from 'services/History'
// import BpInternal from './BpInternal';
import BpInternalAction from './BpInternalAction';
import BpPortal from './BpPortal';

class App extends Component {
  render() {
    return (
      <Router history={history} >
        <div className="root">
        <Navigation />
          <Switch>
            <Route path="/" component={MainPage} exact />
            <Route path="/public-proxy" component={MainPage} exact />
            <Route path="/exchange-proxy" component={MainPage} exact />
            <Route path="/dashboard" component={DashBoard} exact />
            <Route path="/dashboard/proxy/" component={DashBoard} exact />
            <Route path="/dashboard/bp/" component={DashBoard} exact />
            <Route path="/dashboard/proxy/:proxyId" component={ProxyInternal} exact />
            <Route path="/dashboard/proxy/:proxyId/fund-account" component={ProxyInternal} exact />
            <Route path="/dashboard/proxy/:proxyId/refund" component={ProxyInternal} exact />
            <Route path="/dashboard/proxy/:proxyId/set-apr" component={ProxyInternal} exact />
            <Route path="/dashboard/bp/:BpId" component={BpInternal} exact />
            <Route path="/register-proxy" component={RegisterProxy} exact />
            <Route path="/bp-internal-action" component={BpInternalAction} exact />
            <Route path="/bp-portal" component={BpPortal} exact />
            <Route path="/docs" component={Docs} exact />
            <Route path="/docs/policy" component={Docs} exact />
            <Route path="/docs/terms" component={Docs} exact />
            <Route component={ErrorPage} />
          </Switch>

        </div>
      </Router >
    );
  }
}

// Export a redux connected component
export default (App);
