// import Eos from 'eosjs';
// import ScatterEOS from 'scatterjs-plugin-eosjs'
// import ScatterJS from 'scatterjs-core';
import { initDefaultAccessContext } from 'eos-transit';
import scatter from 'eos-transit-scatter-provider';
import tokenpocket from 'eos-transit-tokenpocket-provider';
import meetone from 'eos-transit-meetone-provider';
import lynx from 'eos-transit-lynx-provider';
import simplEOS from 'eos-transit-simpleos-provider';
import AnchorLinkProvider from 'eos-transit-anchorlink-provider';

import { login, logout, loginError, logoutError, resetMessage } from 'services/Action';
import history from 'services/History';

import iziToast from 'izitoast';
import { TOAST, ACCOUNT_CONTRACT, PROXYS_TABLE, BP_TABLE, NORMAL_ACCOUNT, BP_ACCOUNT, PROXY_ACCOUNT } from 'components/global';

const appName = 'Genpool';
const walContext = initDefaultAccessContext({
    appName,
    network: {
        host: process.env.REACT_APP_NETWORK_HOST,
        port: process.env.REACT_APP_NETWORK_PORT,
        protocol: process.env.REACT_APP_NETWORK_PROTOCOL,
        chainId: process.env.REACT_APP_NETWORK_CHAIN_ID
    },
    walletProviders: [
        scatter(),
        tokenpocket(),
        meetone(),
        lynx(),
        simplEOS(),
        AnchorLinkProvider('genpool', {})
    ]
});

export const MAIN_NETWORK = {
    blockchain: process.env.REACT_APP_MAIN_NETWORK_BLOCK_CHAIN,
    protocol: process.env.REACT_APP_NETWORK_PROTOCOL,
    host: process.env.REACT_APP_NETWORK_HOST,
    port: process.env.REACT_APP_NETWORK_PORT,
    chainId: process.env.REACT_APP_NETWORK_CHAIN_ID,
}

// export const MAIN_NETWORK = {
//     blockchain: 'eos',
//     protocol: 'https',
//     host: 'jungle2.cryptolions.io',
//     port: 443,
//     chainId: 'e70aaab8997e1dfce58fbfac80cbbb8fecec7b99cf982a9444273cbc64c41473'
// }

export const GetWalletList = async () => {
    //get the list of available wallet providers
    const walletProviders = await walContext.getWalletProviders();

    if (walletProviders && walletProviders.length) {
        return walletProviders

    } else {
        return null

    }
}

export const LoginWallet = async (dispatch, wallet) => {
    let status = false
    try {
        //initiate the `Wallet`
        const initiatewallet = await walContext.initWallet(wallet)
        if (initiatewallet) {
            //connect to wallet
            const connectToWallet = await initiatewallet.connect()
            if (connectToWallet) {
                //login to wallet
                const signIn = await initiatewallet.login()
                if (signIn) {
                    //dispatch wallet data to props wallet
                    dispatch(login(wallet, signIn))
                    status = true
                    const account = signIn.account_name
                    //   const account = 'oneplayio'
                    //   const account = 'colinrewards' //Proxies Y 
                    //   const account = 'lukeeosproxy' //Proxies N
                    //   const account = 'aus1genereos' //BP Y
                    //   const account = 'eostribeprod' //BP N
                    CheckTypeOfUser(account)
                } else {
                    //dispatch logout
                    dispatch(logout())

                }
            } else {
                //dispatch logout
                dispatch(logout())
            }

        } else {
            //dispatch logout
            dispatch(logout());
            iziToast.show({
                message: 'Failed to initiate wallet',
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }
    } catch (error) {
        console.log(error);
        dispatch(loginError(error))
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        iziToast.show({
                            message: err.error.details[0].message,
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }

            } catch (e) {
                iziToast.show({
                    message: error.toString(),
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
        }
    }
    return status
}

export const LogoutWallet = async (dispatch, wallet) => {
    let status = false
    try {
        dispatch(logout())
        //fetch all wallet list
        const walletProviders = await walContext.getWalletProviders();
        let IdWallet = 0

        //get the current wallet
        for(let i = 0; i < walletProviders.length; ++i){
            if(walletProviders[i].id === wallet.id){
                IdWallet = i
            }
        }

        //initiate the `Wallet`
        const initiatewallet = await walContext.initWallet(walletProviders[IdWallet])
        if (initiatewallet) {
            //connect to wallet
            const connectToWallet = await initiatewallet.connect()
            if (connectToWallet) {
                await initiatewallet.logout()
                await initiatewallet.disconnect()
                status = true

            }
        }
    } catch (error) {
        console.log(error);
        dispatch(logoutError(error))
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        // iziToast.show({
                        //     message: err.error.details[0].message,
                        //     progressBar: false,
                        //     timeout: TOAST.TimeOut,
                        //     backgroundColor: TOAST.WarningColor,
                        //     messageSize: TOAST.MessageSize,
                        //     position: TOAST.Position,
                        //     messageColor: TOAST.MessageColor,
                        // });
                    }
                }

            } catch (e) {
                // iziToast.show({
                //     message: error.toString(),
                //     progressBar: false,
                //     timeout: TOAST.TimeOut,
                //     backgroundColor: TOAST.WarningColor,
                //     messageSize: TOAST.MessageSize,
                //     position: TOAST.Position,
                //     messageColor: TOAST.MessageColor,
                // });
            }
        }
    }
    return status
}

export const resetMsg = async (dispatch) => {
    dispatch(resetMessage())

}

// NOT USE
export const IdentityWallet = async (dispatch, wallet) => {
    let status = false
    dispatch(logout())
    try {
        //fetch all wallet list
        const walletProviders = await walContext.getWalletProviders();
        let IdWallet = 0

        //get the current wallet
        for(let i = 0; i < walletProviders.length; ++i){
            if(walletProviders[i].id === wallet.id){
                IdWallet = i
            }
        }

        //initiate the `Wallet`
        const initiatewallet = await walContext.initWallet(walletProviders[IdWallet])
        if (initiatewallet) {
            //connect to wallet
            const connectToWallet = await initiatewallet.connect()
            if (connectToWallet) {
                const signIn = await initiatewallet.login()
                if (signIn) {
                    dispatch(login(signIn))

                } else {
                    dispatch(logout())

                }
            } else {
                dispatch(logout())
            }

        } else {
            dispatch(logout());
            iziToast.show({
                message: 'Failed to initiate wallet',
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }
    } catch (error) {
        console.log(error);
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        iziToast.show({
                            message: err.error.details[0].message,
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }

            } catch (e) {
                iziToast.show({
                    message: error.toString(),
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
        }
    }
    return status
}

export const fetchTable = async (account, scope, table, lower_bound, upper_bound, limit) => {
    try {
        const response = await fetch(`${MAIN_NETWORK.protocol}://${MAIN_NETWORK.host}:${MAIN_NETWORK.port}/v1/chain/get_table_rows`, {
            method: 'POST',
            body: JSON.stringify({
                code: account,
                scope: scope,
                table: table,
                lower_bound: lower_bound,
                upper_bound: upper_bound,
                index_position: "1",
                key_type: "i64",
                reverse: "true",
                json: "true",
                limit: limit,
            }),
        });
        const responseJson = await response.json();
        return responseJson.rows;

    } catch (error) {
        console.log(error);
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        iziToast.show({
                            message: err.error.details[0].message,
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }

            } catch (e) {
                iziToast.show({
                    message: e,
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
                return e
            }
        }
        return error
    }
}

export const pushAction = async (wallet, account, action, actor, data, permission = 'active') => {
    let status = false
    try {
        const transaction = {
            actions: [{
                account,
                name: action,
                authorization: [{
                    actor,
                    permission
                }],
                data
            }]
        }

        const option = {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 60
        }

        //fetch all wallet list
        const walletProviders = await walContext.getWalletProviders();
        let IdWallet = 0

        //get the current wallet
        for(let i = 0; i < walletProviders.length; ++i){
            if(walletProviders[i].id === wallet.id){
                IdWallet = i
            }
        }

        //initiate the `Wallet`
        const initiatewallet = await walContext.initWallet(walletProviders[IdWallet])
        // console.log('tam initiatewallet', initiatewallet, wallet)
        if (initiatewallet) {
            //connect to wallet
            const connectToWallet = await initiatewallet.connect()
            // console.log('tam conenct', connectToWallet)
            if (connectToWallet) {
                //login to wallet
                const signIn = await initiatewallet.login()
                if (signIn) {
                    //send action
                    // console.log('tam transaction', transaction)
                    const tx = await initiatewallet.eosApi.transact(transaction, option)
                    if (tx) {
                        status = true
                    }
                }
            }
        } else {
            iziToast.show({
                message: 'Failed to initiate wallet',
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }

    } catch (error) {
        console.log( error);
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        iziToast.show({
                            message: err.error.details[0].message,
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }

            } catch (e) {
                iziToast.show({
                    message: error.toString(),
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
        }
    }
    return status
}

export const pushDoubleAction = async (wallet, account1, account2, action1, action2, actor, data1, data2, permission = 'active') => {
    let status = false
    try {
        const transaction = {
            actions: [{
                account: account1,
                name: action1,
                authorization: [{
                    actor,
                    permission
                }],
                data: data1,
            },
            {
                account: account2,
                name: action2,
                authorization: [{
                    actor,
                    permission
                }],
                data: data2,
            }]
        }

        const option = {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 60
        }

        //fetch all wallet list
        const walletProviders = await walContext.getWalletProviders();
        let IdWallet = 0

        //get the current wallet
        for(let i = 0; i < walletProviders.length; ++i){
            if(walletProviders[i].id === wallet.id){
                IdWallet = i
            }
        }

        //initiate the `Wallet`
        const initiatewallet = await walContext.initWallet(walletProviders[IdWallet])
        if (initiatewallet) {
            //connect to wallet
            const connectToWallet = await initiatewallet.connect()
            if (connectToWallet) {
                //login to wallet
                const signIn = await initiatewallet.login()
                if (signIn) {
                    const tx = await initiatewallet.eosApi.transact(transaction, option)
                    if (tx) {
                        status = true
                    }
                }
            }
        } else {
            iziToast.show({
                message: 'Failed to initiate wallet',
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        }

    } catch (error) {
        console.log( error);
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        iziToast.show({
                            message: err.error.details[0].message,
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }

            } catch (e) {
                iziToast.show({
                    message: error.toString(),
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
        }
    }
    return status
}

export const imageExists = (image_url) => {
    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status !== 404;
}

export const CheckTypeOfUser = async (account) => {
    const AccountType = await fetchTable('eosio', 'eosio', 'voters', account, account, 100);

    // console.log('tam AccountType', AccountType)
    if (AccountType && AccountType.length && AccountType[0] && AccountType[0]) {
        if (AccountType[0].is_proxy) {
            //proxy account
            const IsProxyReg = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, PROXYS_TABLE, account, account, 50);
            // console.log('tam IsProxyReg', IsProxyReg)

            if (IsProxyReg && IsProxyReg.length) {
                //already registered with GenPool, then go to their "Proxy management" dashboard 
                history.push('/dashboard/proxy/' + account)
            } else {
                //new proxy and not registered yet, show them the "Proxy registration" form
                history.push('/register-proxy')
            }
        } else {
            //Voter acoount
            const tmpAccount = AccountType[0].owner
            const IsNormalAccount = await fetchTable('eosio', 'eosio', 'producers', tmpAccount, tmpAccount, 100);
            // console.log('tam IsNormalAccount', IsNormalAccount)

            //Bp Account
            if (IsNormalAccount && IsNormalAccount.length) {
                const IsBpReg = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, BP_TABLE, account, account, 100);
                //   console.log('tam IsBpReg', IsBpReg)

                if (IsBpReg && IsBpReg.length) {
                    // already registered with GenPool, then go to their page for "Funding" page
                    history.push('/dashboard/bp/' + account)
                } else {
                    //new BP, show them the "BP registration" form
                    history.push('/dashboard/bp')
                }
                //normal account
            } else {
                //don't exits in table => normal account => do nothing
            }
        }
    } else {
        //normal account
        //don't exits in table => normal account => do nothing
    }
}

export const GetTokenInfo = async (account) => {
    const symbol = "EOS"
    const code = "eosio.token"
    try {
        const response = await fetch(`${MAIN_NETWORK.protocol}://${MAIN_NETWORK.host}:${MAIN_NETWORK.port}/v1/chain/get_currency_balance`, {
            method: 'POST',
            body: JSON.stringify({
                code: code,
                account: account,
                symbol: symbol,
            }),
        });

        const TokenValue = await response.json();
        // console.log('tam token', TokenValue, account)
        return TokenValue;
    } catch (error) {
        console.log(error);
        if (error && error.isError) {
            iziToast.show({
                message: error.message,
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });
        } else if (error) {
            try {
                var err = JSON.parse(error);

                if (err && typeof err === "object") {
                    if (err && err.error && err.error.details && err.error.details.length) {
                        iziToast.show({
                            message: err.error.details[0].message,
                            progressBar: false,
                            timeout: TOAST.TimeOut,
                            backgroundColor: TOAST.WarningColor,
                            messageSize: TOAST.MessageSize,
                            position: TOAST.Position,
                            messageColor: TOAST.MessageColor,
                        });
                    }
                }

            } catch (e) {
                iziToast.show({
                    message: error.toString(),
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.WarningColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }
        }
    }

}

export const UserType = async (account) => {
    let typeAccount = NORMAL_ACCOUNT

    const AccountType = await fetchTable('eosio', 'eosio', 'voters', account, account, 100);

    if (AccountType && AccountType.length && AccountType[0] && AccountType[0]) {
        if (AccountType[0].is_proxy) {
            //proxy account
            typeAccount = PROXY_ACCOUNT

        } else {
            //Voter acoount
            const tmpAccount = AccountType[0].owner
            const IsNormalAccount = await fetchTable('eosio', 'eosio', 'producers', tmpAccount, tmpAccount, 100);
            // console.log('tam IsNormalAccount', IsNormalAccount)

            //Bp Account
            if (IsNormalAccount && IsNormalAccount.length) {

                typeAccount = BP_ACCOUNT
                //normal account
            } else {
                //don't exits in table => normal account => do nothing
            }
        }
    } else {
        //normal account
        //don't exits in table => normal account => do nothing
    }

    return typeAccount
}

export const GetProxiesTable = async () => {
    try {
        const response = await fetch(`https://us-central1-genpool-ad9fa.cloudfunctions.net/getGenPoolProxies`, {
            method: 'GET',
        });
        
        const responseJson = await response.json();
        return responseJson;

    } catch (error) {
        console.log(error);
        iziToast.show({
            message: error.toString(),
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
        return error
    }
}

export const GetReward = async () => {
    try {
        const response = await fetch(`https://us-central1-genpool-ad9fa.cloudfunctions.net/get_reward`, {
            method: 'GET',
        });
        
        const responseJson = await response.json();
        return responseJson;

    } catch (error) {
        console.log(error);
        iziToast.show({
            message: error.toString(),
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
        return error
    }
}

export const GetHistoryApr = async () => {
    try {
        const response = await fetch(`https://us-central1-genpool-ad9fa.cloudfunctions.net/get_history_apr`, {
            method: 'GET',
        });
        
        const responseJson = await response.json();
        return responseJson;

    } catch (error) {
        console.log(error);
        iziToast.show({
            message: error.toString(),
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.WarningColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
        return error
    }
}

class ApiService {

}

export default ApiService;