// React core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import OutsideClickHandler from 'react-outside-click-handler';
import { multilanguage, changeLanguage, loadLanguages } from 'redux-multilanguage';

import { LoginWallet, LogoutWallet, IdentityWallet, GetWalletList, resetMsg, UserType } from 'services/ApiService';
import { LOGIN_ERROR } from 'services/Action';
import { EN, CH, KR, BP_ACCOUNT, PROXY_ACCOUNT } from 'components/global';

import WalletList from './WalletList';
import Referral from './Referral';

import Logo from 'assets/images/Genpool-logo.png';
import Hamburger from 'assets/images/Hamburger.svg';
import EN_Flag from 'assets/images/English.svg';
import CH_Flag from 'assets/images/China.svg';
import KR_Flag from 'assets/images/Korea.svg';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsShowMenuMobile: false,
            IsLogin: false,

            IsToggleMenuLang: false,
            IsToggleWalletPopUp: false,

            scatterName: '',
            walletList: [],

            IsloadingAction: false,

            isDashBoard: false,
            isReferralPopUp: false,
            isCheckAccountType: true,

        }
    }

    componentDidMount = async () => {
        this.loadLanguages()
        const listWallet = await GetWalletList()

        if(listWallet && listWallet.length){
            this.setState({
                walletList: listWallet
            })
        }
        // this.startTimer(2000)

        this.IsDisplayDashBoard()
    }

    IsDisplayDashBoard = async () => {
        if(this.props.wallet && this.props.wallet.data){
            const account = this.props.wallet.data.account_name
            //   const account = 'colinrewards' //Proxies Y 
            //   const account = 'aus1genereos' //BP Y
            const typeUser = await UserType(account)

            // console.log('tam type', typeUser)
            if(typeUser === PROXY_ACCOUNT || typeUser === BP_ACCOUNT){
                this.setState({
                    isDashBoard: true,
                    isCheckAccountType: false,
                })
            }else{
                this.setState({
                    isDashBoard: false,
                    isCheckAccountType: false,
                })
            }
        }else{
            this.setState({
                isDashBoard: false,
                isCheckAccountType: false,
            })
        }
    }

    startTimer = (timer) => {
        this.timer = setInterval(this.systemTick, timer)
    }
    stopTimer = () => {
        clearInterval(this.timer)
    }

    systemTick = () => {
        this.checkConnectWallet()
    }

    checkConnectWallet = async () => {
        if(this.props.message && (this.props.message.type === LOGIN_ERROR)){
            this.props.clearMsg()
            this.closeWalletListPopUp()
        }

        if(this.props.wallet){
            this.closeWalletListPopUp()
            this.IsDisplayDashBoard()
        }
    }

    componentWillUnmount = () => {
        // document.removeEventListener('click', this.closeMenuMobile, false);
        this.props.clearMsg()
    }

    toggleMenuLang = () => {
        this.setState({
            IsToggleMenuLang: !this.state.IsToggleMenuLang
        })
    }

    hideToggleMenuLang = () => {
        this.setState({
            IsToggleMenuLang: false,
        })
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    changeLanguages = (lang) => () => {
        this.props.dispatch(changeLanguage(lang))
    }

    toggleMenuMobile = () => {
        this.setState({
            IsShowMenuMobile: !this.state.IsShowMenuMobile
        })
    }

    closeMenuMobile = () => {
        this.setState({
            IsShowMenuMobile: false,
        })
    }

    closeWalletListPopUp = () => {
        this.setState({
            IsToggleWalletPopUp: false
        })
        this.stopTimer()
    }

    closeReferralPopUp = () => {
        this.setState({
            isReferralPopUp: false,
        })
    }

    showWalletList = () => {
        this.setState({
            IsShowMenuMobile: false,
        })
        if(this.props.wallet === null){
            this.startTimer(500)
            this.setState({
                IsToggleWalletPopUp: !this.state.IsToggleWalletPopUp
            })
        }else{
            this.props.logout(this.props.wallet.wallet)
            this.setState({
                isDashBoard: false,
            })
        }
    }

    isShowReferralPopUp = () => {
        this.setState({
            isReferralPopUp: !this.state.isReferralPopUp
        })
    }

    selectWallet = () => (wallet) => {
        // this.closeWalletListPopUp()
        this.props.login(wallet)

        this.setState({
            isCheckAccountType: true,
        })

    }

    render() {
        var wallet = this.props.wallet
        const { strings } = this.props
        return (
            <div>
                {
                    this.state.IsToggleWalletPopUp ? 
                        <WalletList onClosePopUp={this.closeWalletListPopUp} onSelectWallet={this.selectWallet()} listWall={this.state.walletList} {...this.props}/> : null
                }
                {
                    this.state.isReferralPopUp ?
                        <Referral onClosePopUp={this.closeReferralPopUp} {...this.props}/> : null
                }
                <div className="newnav">
                    <Link to="/" className="link-block-3 w-inline-block w--current">
                        <img src={Logo} width={114} alt="" className="image-8" />
                    </Link>
                    <Link to="#" className="mobile-trigger w-inline-block" onClick={this.toggleMenuMobile} ref={(node) => { this.node = node }}>
                        <img src={Hamburger} width={29} alt="" />
                    </Link>
                    <div className="div-block-18">
                        <Link to="/" className="link-block w-inline-block w--current">
                            <div className="text-block-16">{strings["Home"]}</div>
                        </Link>
                        {
                            wallet !== null ?
                                <div className="link-block w-inline-block" onClick={this.isShowReferralPopUp}>
                                    <div className="text-block-16">{strings["Referral"]}</div>
                                </div> : null
                        }
                        {
                            this.state.isDashBoard ? 
                                <Link to="/dashboard" className="link-block w-inline-block">
                                    <div className="text-block-16">{strings["Dashboard"]}</div>
                                </Link> : null
                        }
                        <Link to="/register-proxy" className="link-block w-inline-block">
                            <div className="text-block-16">{strings["Register_Proxy"]}</div>
                        </Link>
                        <a href="https://genpool.zendesk.com/hc/en-us" className="link-block w-inline-block">
                            <div className="text-block-16">{strings["FAQ"]}</div>
                        </a>
                        <a href="https://www.genereos.io/index.php/blog-2/" className="link-block w-inline-block">
                            <div className="text-block-16">{strings["blog"]}</div>
                        </a>
                        <Link to="#" className="link-block w-inline-block" onClick={this.showWalletList}>
                            {
                                wallet === null ? 
                                <div className="text-block-16 login" >{strings["Login"]}</div> 
                                    : <div className="text-block-16 login" >{wallet.data.account_name}</div>
                            }
                        </Link>
                        <div className="link-block w-inline-block">
                            {
                                <OutsideClickHandler onOutsideClick={this.hideToggleMenuLang}>
                                    <div className="lang-div" onClick={this.toggleMenuLang}>
                                        <div className={`lang-wrap ${this.state.IsToggleMenuLang ? 'active' : ''}`} >
                                            <div className="link-block-16 first w-inline-block" onClick={this.changeLanguages(EN)}>
                                                <img src={EN_Flag} width={40} alt=" " className="image-65" />
                                                <div className="text-block">{strings["EN"]}</div>
                                            </div>
                                            <div className="link-block-16 normal w-inline-block" onClick={this.changeLanguages(CH)}>
                                                <img src={CH_Flag} width={40} alt=" " className="image-65" />
                                                <div className="text-block">{strings["CH"]}</div>
                                            </div>
                                            <div className="link-block-16 last w-inline-block" onClick={this.changeLanguages(KR)}>
                                                <img src={KR_Flag} width={40} alt=" " className="image-65" />
                                                <div className="text-block">{strings["KR"]}</div>
                                            </div>
                                        </div>

                                        {
                                            this.props.currentLanguageCode === EN ? <div className="div-block-45"><img src={EN_Flag} width={50} alt=" " /></div> : null
                                        }
                                        {
                                            this.props.currentLanguageCode === CH ? <div className="div-block-45"><img src={CH_Flag} width={50} alt=" " /></div> : null
                                        }
                                        {
                                            this.props.currentLanguageCode === KR ? <div className="div-block-45"><img src={KR_Flag} width={50} alt=" " /></div> : null
                                        }
                                    </div>
                                </OutsideClickHandler>
                            }
                        </div>
                    </div>

                    <div className={`mobile-menu${this.state.IsShowMenuMobile ? '-acitve' : ''}`} >
                        <OutsideClickHandler onOutsideClick={this.closeMenuMobile}>
                            <Link to="/" className="link-block mobile w-inline-block w--current" onClick={this.closeMenuMobile}>
                                <div className="text-block-16">{strings["Home"]}</div>
                            </Link>
                            {
                                this.state.isDashBoard ? 
                                    <Link to="/dashboard" className="link-block mobile w-inline-block" onClick={this.closeMenuMobile}>
                                        <div className="text-block-16">{strings["Dashboard"]}</div>
                                    </Link> : null
                            }
                            <Link to="/register-proxy" className="link-block mobile w-inline-block" onClick={this.closeMenuMobile}>
                                <div className="text-block-16">{strings["Register_Proxy"]}</div>
                            </Link>
                            <a href="https://genpool.zendesk.com/hc/en-us" className="link-block mobile w-inline-block">
                                <div className="text-block-16">{strings["FAQ"]}</div>
                            </a>
                            <a href="https://www.genereos.io/index.php/blog-2/" className="link-block mobile w-inline-block">
                                <div className="text-block-16">{strings["blog"]}</div>
                            </a>
                            <Link to="#" className="link-block mobile w-inline-block" onClick={this.showWalletList}>
                                {
                                    wallet === null ? 
                                    <div className="text-block-16" >{strings["Login"]}</div> 
                                        : <div className="text-block-16" >{wallet.data.account_name}</div>
                                }
                            </Link>
                            <div className="link-block mobile w-inline-block" onClick={this.closeMenuMobile}>
                                <img src={EN_Flag} width={`${this.props.currentLanguageCode === EN ? '70' : '40'}`} alt="" onClick={this.changeLanguages(EN)} />
                                <img src={CH_Flag} width={`${this.props.currentLanguageCode === CH ? '70' : '40'}`} alt="" onClick={this.changeLanguages(CH)} />
                                <img src={KR_Flag} width={`${this.props.currentLanguageCode === KR ? '70' : '40'}`} alt="" onClick={this.changeLanguages(KR)} />
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        wallet: state.wallet,
        message: state.message,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        indetify: (wallet) => {
            IdentityWallet(dispatch, wallet);
        },
        login: (wallet) => {
            LoginWallet(dispatch, wallet);
        },
        logout: (wallet) => {
            LogoutWallet(dispatch, wallet);
        },
        clearMsg: () => {
            resetMsg(dispatch)
        }
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(Navigation))