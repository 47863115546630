// React core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import iziToast from 'izitoast';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import OutsideClickHandler from 'react-outside-click-handler';
import { TOAST, ESC, REFERRAL_TABLE, ACCOUNT_CONTRACT } from 'components/global';
import { fetchTable } from 'services/ApiService';

import Close_Window from 'assets/images/close.svg';
import spin from 'assets/images/spiner.svg';

class Referral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listReferral: [],
            referralLink: '',
            isLoadRefList: false,
        }
    }

    componentDidMount = async () => {
        this.loadLanguages();
        this.loadReferral();
        document.addEventListener("keydown", this.closePopUp, false);
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    loadReferral = async () => {
        if(this.props.wallet && this.props.wallet.data){
            const account = this.props.wallet.data.account_name
            // const account = "arymaxraysi4"

            this.setState({
                referralLink: window.location.protocol + '//' + window.location.host + '/?ref=' + account,
                isLoadRefList: true
            })

            const refTable = await fetchTable(ACCOUNT_CONTRACT, ACCOUNT_CONTRACT, REFERRAL_TABLE, account, account, 100, 1);

            // console.log('tam refTa', refTable)

            if(refTable && refTable.length){
                this.setState({
                    listReferral: refTable,
                })
            }
        }
        this.setState({
            isLoadRefList: false
        })
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.closePopUp, false);
    }

    closePopUp = (key) => {
        if (key.which === ESC) {
            this.closeLayout()
        }
    }
    

    closeLayout = () => {
        this.props.onClosePopUp()
    }

    handleCopyReferralLink = () => {
        var copyText = this.state.referralLink;
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = copyText;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard

        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);

        iziToast.show({
            message: this.props.strings["ref_copied"],
            progressBar: false,
            timeout: TOAST.TimeOut,
            backgroundColor: TOAST.SucessColor,
            messageSize: TOAST.MessageSize,
            position: TOAST.Position,
            messageColor: TOAST.MessageColor,
        });
    }

    render() {
        let myListReferral = this.state.listReferral && this.state.listReferral.length ? this.state.listReferral.map((ref, index) => {
            return (
                <div key={index} className="pop-up-block-18">
                    <div className="pop-up-block-19">{ref.voter}</div>
                    <div className="pop-up-block-19">{ref.eos_earn_amount}</div>
                </div>
            )
        }) : 
            <div className="pop-up-block-18">
                <div className="pop-up-block-18-text">{this.props.strings["ref_Noone"]}</div>
            </div>

        const { strings } = this.props
        return (
            <div className="pop-up">
                <div className="pop-up-wrap Referral" >
                    <OutsideClickHandler onOutsideClick={this.closeLayout}>
                        <div className="pop-up-block-1" >
                            <div className="pop-up-block-2" onClick={this.closeLayout}><img src={Close_Window} width={25} alt="" /></div>
                        </div>
                        <div className="pop-up-block-10" >
                            <div className="pop-up-block-12" >{strings["ref_title_1"]}<br/> {strings["ref_title_2"]}</div>
                        </div>
                        <div className="pop-up-block-13" >
                            <input type="text" className="text-field w-input referral-link" disabled="disable"
                                 value={this.state.referralLink} placeholder={this.state.referralLink}/>
                            <div className="button-referral-btn" onClick={this.handleCopyReferralLink}>{strings["ref_copy"]}</div>
                        </div>

                        <div className="pop-up-block-10" >
                            <div className="pop-up-block-14" >{strings["ref_des_1"]}</div>
                        </div>

                        <div className="pop-up-block-15">
                            <div className="pop-up-block-16">{strings["ref_title_3"]}</div>
                            <div className="pop-up-block-16">{strings["ref_title_4"]}</div>
                        </div>

                        <div className="pop-up-block-17" id="scroll-ref">
                            {
                                this.state.isLoadRefList ?
                                    <div className="rowrewarddiv-loading">
                                        <img src={spin} width={100} alt="" className="image-12" />
                                    </div> : myListReferral
                            }
                        </div>

                    </OutsideClickHandler>
                </div>
            </div>
        );
    }
}

// Export a redux connected component
export default connect()(multilanguage(Referral))
