import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Footer, Spin } from 'components'
import CountUp from 'react-countup'
import { multilanguage, loadLanguages } from 'redux-multilanguage'
import { Link } from "react-router-dom"
import {
    AreaChart, Area, XAxis, YAxis, Tooltip, linearGradient, Label
  } from 'recharts'

import iziToast from 'izitoast'
import { TOAST, ACCOUNT_CONTRACT, SUCCESS } from 'components/global'
import NumberFormat from 'react-number-format'

import { LoginWallet, LogoutWallet, IdentityWallet, pushDoubleAction, GetProxiesTable, GetReward, GetHistoryApr } from 'services/ApiService'

import EOS_Main100 from 'assets/images/EOS-Main100.svg'
// import mJafI from 'assets/images/mJafI-2.png'
import mJafI from 'assets/images/meetone_logo.png'
import msbg3 from 'assets/images/msbg3-2.png'
import mYxkZ from 'assets/images/mYxkZ-2.png'
import mi5Pz from 'assets/images/mi5Pz-2.png'
import Logo_06nJ from 'assets/images/06nJ-2.png'
import mkDyx from 'assets/images/eosnation.png'
import Logo_2Mk3 from 'assets/images/2Mk3-2.png'
import Cmvk from 'assets/images/Cmvk-2.png'
import Logo_2Gv6 from 'assets/images/2Gv6-2.png'
import lvAW from 'assets/images/lvAW-2.png'
import m76HF from 'assets/images/m76HF-2.png'
import mitVk from 'assets/images/mitVk-2.png'
import mteG8 from 'assets/images/mteG8-2.png'
import mc4Hb from 'assets/images/mc4Hb-2.png'
import m3doB from 'assets/images/m3doB-2.png'
import spin from 'assets/images/spiner.svg'
import info_topup from 'assets/images/info.svg'
import sending from 'assets/images/sending_dark.svg'

const PUBLIC = 0
const PUBLIC_PATH = '/public-proxy'
const EXCHANGE = 1
const EXCHANGE_PATH = '/exchange-proxy'
// const NUMBER_OF_EXCHANGE_ACCOUNT = 15
const PATTERN_REF_LINK = 'ref'
const INCOMING_DAY = 27
const REMAIN_DAY = 6
const MAX_DAY_PER_MONTH = 32

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsloadingTable: false,  //loading icon in table
            IsLoading: false,        //loading icon when push transaction
            RewardProxyTable: [],   //data in proxytable
            exchangeProxyTable: [],
            historyApr: [],

            totalEOS: 0,            //total eso of all proxy
            tabId: PUBLIC,

            isSendingActionList: [],
            isShowMoreInformation: [],
            isShowMoreInformationEx: [],
            referralUser: '',

            incomingDays: new Date().getDate(),
            isComingDays: false,
            eosTotalReward: 0,
            genBurn: 0,

        }
    }

    componentDidMount = async () => {
        if (this.props.location && this.props.location.pathname === PUBLIC_PATH) {
            this.setState({
                tabId: PUBLIC,
            })

        } else if (this.props.location && this.props.location.pathname === EXCHANGE_PATH) {
            this.setState({
                tabId: EXCHANGE,
            })
        }

        this.loadLanguages();
        this.initializeProxyTable();
        this.initializeReferralUser();
        this.initializeInforReward();
    }

    loadLanguages = () => {
        // Below is to load languages, must call this once on app start,
        // and when user switch to new language that haven't loaded yet.
        this.props.dispatch(loadLanguages({
            languages: {
                ch: require('components/Lang/ch.json'),
                en: require('components/Lang/en.json'),
                kr: require('components/Lang/kr.json'),
            }
        }))
    }

    getParameterFromUrl = (url, parm) => {
        var re = new RegExp(".*[?&]" + parm + "=([^&]+)(&|$)");
        var match = url.match(re);
        return (match ? match[1] : "");
    }

    initializeInforReward = async () => {
        const now = new Date().getDate()
        let incomingDays = INCOMING_DAY - now
        if(incomingDays < 0){
            incomingDays = INCOMING_DAY + new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate() - now
        }

        this.setState({
            incomingDays: incomingDays,
            isComingDays: incomingDays < REMAIN_DAY ? true : false
        })

        const reward = await GetReward()

        if(reward && reward.code === SUCCESS && reward.data){
            this.setState({
                genBurn: parseFloat(reward.data.GEN),
                eosTotalReward: parseFloat(reward.data.EOS)
            })
        }
    }

    initializeReferralUser = () => {
        const refLink = window.document.location.href
        let ref = '';

        if (refLink) {
            ref = this.getParameterFromUrl(refLink, PATTERN_REF_LINK)
            // console.log('tam Id', ref)
            this.setState({
                referralUser: ref
            })
        }
    }

    initializeProxyTable = async () => {
        this.setState({
            IsloadingTable: true,
        })

        let tmpTable = []
        let tmpExTable = []
        let total = 0

        const ProxyTable = await GetProxiesTable()
        // console.log('tam ', ProxyTable, !ProxyTable.hasError)
        if (ProxyTable && ProxyTable.data) {
            // console.log('tam proxyTable', ProxyTable.data())
            for (let i = 0; i < ProxyTable.data.publicProxies.length; ++i) {
                tmpTable.push(ProxyTable.data.publicProxies[i])
            }
            for (let i = 0; i < ProxyTable.data.exchangeProxies.length; ++i) {
                tmpExTable.push(ProxyTable.data.exchangeProxies[i])
            }
            total = ProxyTable.data.summary.total
        }

        // console.log('tam data', tmpTable)
        // console.log('tam data', tmpExTable)

        //sort follow stake eos
        tmpTable = tmpTable.sort((a, b) => (parseFloat(a.stakeEOS) < parseFloat(b.stakeEOS) ? 1 : -1))
        tmpExTable = tmpExTable.sort((a, b) => (parseFloat(a.stakeEOS) < parseFloat(b.stakeEOS) ? 1 : -1))

        let tempSendingActionList = []
        let tempShowMoreInformation = []
        let tempShowMoreInformationEx = []
        for (let i = 0; i < tmpTable.length; ++i) {
            tempSendingActionList.push(false)
            const information = {
                isShow: false,
                EOSAmount: 0,
                EOSDaily: 0,
                EOSAnnually: 0,
            }
            tempShowMoreInformation.push(information)
        }
        for (let i = 0; i < tmpExTable.length; ++i) {
            const information = {
                isShow: false,
                EOSAmount: 0,
                EOSDaily: 0,
                EOSAnnually: 0,
            }
            tempShowMoreInformationEx.push(information)
        }

        const hisApr = await GetHistoryApr()
        let listHistApr = []
        let listDataHisApr = {}

        //get list proxies's history of apr
        if(hisApr && hisApr.code === SUCCESS && hisApr.data){
            for(let i = 1; i < MAX_DAY_PER_MONTH; ++i){
                listHistApr.push(hisApr.data[`${i}`])
            }

            //sort follow time
            listHistApr = listHistApr.sort((item1, item2) => new Date(item1.timeUpdate) - new Date(item2.timeUpdate))
        }

        // console.log('tam listHistApr', listHistApr)

        if(tmpTable && tmpTable.length && listHistApr && listHistApr.length){
            //create empty list data for each proxies
            for(let i = 0; i < tmpTable.length; ++i){
                listDataHisApr[`${tmpTable[i].name}`] = []
            }
            //querry all proxies table
            for(let i = 0; i < tmpTable.length; ++i){
                //querry all list apr
                for(let ii = 0; ii < listHistApr.length; ++ii){
                    let proxiesApr = {
                        time: '',
                        apr: 0,
                    }
                    //ad time
                    proxiesApr.time = `${new Date(listHistApr[ii].timeUpdate).getDate()}/${new Date(listHistApr[ii].timeUpdate).getMonth() + 1}`
                    if(listHistApr[ii].proxieData && listHistApr[ii].proxieData.length){
                        for(let iii = 0; iii < listHistApr[ii].proxieData.length; ++iii){
                            //match proxies > add apr data
                            if(listHistApr[ii].proxieData[iii].proxies === tmpTable[i].name){
                                proxiesApr.apr = parseFloat(parseFloat(listHistApr[ii].proxieData[iii].apr) * 100).toFixed(2)
                                break
                            }
                        }
                    }
                    listDataHisApr[`${tmpTable[i].name}`].push(proxiesApr)
                }
            }
        }

        // console.log('tam listDataHisApr', listDataHisApr)

        this.setState({
            RewardProxyTable: tmpTable,
            exchangeProxyTable: tmpExTable,
            historyApr: listDataHisApr,
            IsloadingTable: false,
            totalEOS: total,
            isSendingActionList: tempSendingActionList,
            isShowMoreInformation: tempShowMoreInformation,
            isShowMoreInformationEx: tempShowMoreInformationEx,
        })
    }

    componentWillUnmount = () => {

    }

    updateStatusActionList = (index, isSending) => {
        let actionList = this.state.isSendingActionList

        for (let i = 0; i < actionList.length; ++i) {
            if (i === index) {
                actionList[i] = isSending
            }
        }
        this.setState({
            isSendingActionList: actionList
        })
    }

    showMoreInformation = (data, index) => () => {
        let showList = data

        for (let i = 0; i < showList.length; ++i) {
            if (i === index) {
                showList[i].isShow = !showList[i].isShow
            }
        }
        this.setState({
            isShowMoreInformation: showList
        })
    }

    updateEOSAmount = (index, aprVal) => (EosAmount) => {
        let showList = this.state.isShowMoreInformation

        for (let i = 0; i < showList.length; ++i) {
            if (i === index) {
                showList[i].EOSAmount = EosAmount.value
                showList[i].EOSDaily = parseFloat((EosAmount.value * parseFloat(aprVal)) / (100 * 365)).toFixed(3)
                showList[i].EOSAnnually = parseFloat((EosAmount.value * parseFloat(aprVal)) / (100)).toFixed(3)
            }
        }
        this.setState({
            isShowMoreInformation: showList
        })
    }

    handleVoteEarn = () => {
        window.scrollTo(0, this.myRef.offsetTop)
    }

    handleVoteProxy = (index, proxyName) => async () => {
        // this.setState({
        //   IsLoading: true,
        // })
        // console.log('tam vote proxy', proxyName)
        if (this.props.wallet && this.props.wallet.data) {
            this.updateStatusActionList(index, true)
            const actor = this.props.wallet.data.account_name
            const permission = this.props.wallet.data.permissions[0].perm_name
            const wallet = this.props.wallet.wallet

            const account = 'eosio'
            const action = 'voteproducer'

            const data = {
                voter: actor,
                proxy: proxyName,
                producers: '',
            }

            const account2 = ACCOUNT_CONTRACT
            const action2 = 'regvoter'
            const data2 = {
                voter: actor,
                referrer: this.state.referralUser ? this.state.referralUser : ACCOUNT_CONTRACT,
            }

            //   if(this.state.referralUser){
            const voteproducer = await pushDoubleAction(wallet, account, account2, action, action2, actor, data, data2, permission)
            //   }else{
            //     voteproducer = await pushAction(wallet, account, action, actor, data, permission)
            //   }

            //   const voteproducer = await pushAction(wallet, account, action, actor, data, permission)
            if (voteproducer) {
                iziToast.show({
                    message: this.props.strings["VoteSuccessful"],
                    progressBar: false,
                    timeout: TOAST.TimeOut,
                    backgroundColor: TOAST.SucessColor,
                    messageSize: TOAST.MessageSize,
                    position: TOAST.Position,
                    messageColor: TOAST.MessageColor,
                });
            }

            this.updateStatusActionList(index, false)
            // console.log('tam voteproducer', voteproducer)
        } else {
            iziToast.show({
                message: this.props.strings["PlsLoginScatter"],
                progressBar: false,
                timeout: TOAST.TimeOut,
                backgroundColor: TOAST.WarningColor,
                messageSize: TOAST.MessageSize,
                position: TOAST.Position,
                messageColor: TOAST.MessageColor,
            });

        }
        // this.setState({
        //   IsLoading: false,
        // })
    }

    selectTab = (id) => () => {
        this.setState({
            tabId: id
        })
    }

    shortString = (string) => {
        let ret = ''
        if (string.length > 13) {
            ret = string.substring(0, 13) + '...'

        } else {
            ret = string

        }
        return ret
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    render() {
        const { strings } = this.props

        var proxyTable = this.state.RewardProxyTable ? this.state.RewardProxyTable.map((data, index) => {
            return [
                <tr key={index} className="row100-body">
                    <td className="column2">
                        <div className="column-text">{index + 1}</div>
                    </td>
                    <td className="column3">
                        <img src={data.logo_256} width={32} height={32} alt="" className="image-12" />
                        <div className="column-text name">{data.name}</div>
                    </td>
                    <td className="column4">
                        <div className="column-text">{data.stakeEOS.toLocaleString('en')}</div>
                    </td>
                    <td className="column5">
                        <div className="column-text">{data.frequency}</div>
                    </td>
                    <td className="column8">
                        <div className="column-text">{data.APR}</div>
                    </td>
                    {/* <td className="column8"> */}
                    {/* <div className="column-text">{data.REX}</div> */}
                    {/* </td> */}
                    <td className="column9">
                        {
                            this.state.isShowMoreInformation[index].isShow ?
                                <div className="infobutton mainInfoBtn" onClick={this.showMoreInformation(this.state.isShowMoreInformation, index)}> {strings["Info"]} &#x2227; </div>
                                : <div className="infobutton mainInfoBtn" onClick={this.showMoreInformation(this.state.isShowMoreInformation, index)}> {strings["Info"]} &#x2228; </div>

                        }
                        <div className="link-11 mainVoteBtn" onClick={this.handleVoteProxy(index, data.name)}>
                            {
                                this.state.isSendingActionList[index] ?
                                    <img src={sending} alt="" className="image-main-1" />
                                    : <div>{strings["Vote"]}</div>
                            }
                        </div>
                    </td>
                </tr>,
                <tr key={-index} className={`row-addition ${this.state.isShowMoreInformation[index].isShow ? '' : 'hide'}`}>
                    <td className="row-addition1" colspan="8">
                        {
                            <div className="area-char-main">
                                <div className="area-char-main-title">
                                    <div className="area-char-main-title-text">{strings["Genpool Income Trend"]}</div>
                                    <div className="area-char-main-title-text">{strings["Highest APR Yday"]}: <b>{Math.max.apply(Math, this.state.historyApr[`${data.name}`].map(max => max.apr))} %</b></div>
                                </div>
                                <AreaChart width={700} height={280} data={this.state.historyApr[`${data.name}`]}
                                    margin={{ top: 10, right: 10, left: 10, bottom: 30, }} >
                                    <XAxis dataKey="time">
                                        <Label value={strings["Date"]} offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: strings["Yield"], angle: -90, position: 'insideLeft' }} />

                                    <Tooltip />
                                    <Area type="monotone" dataKey="apr" stroke="#2cbae0" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </div>
                        }
                    </td>
                </tr>,
                <tr key={-index} className={`row-addition ${this.state.isShowMoreInformation[index].isShow ? '' : 'hide'}`}>
                    <td className="row-addition1" colspan="2">
                        <div className="row-addition-text title">Details</div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Account: `}</div>
                            <div className="row-addition-text2">{data.name}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied EOS: `}</div>
                            <div className="row-addition-text2">{parseFloat(data.stakeEOS).toLocaleString('en')}</div></div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Candidate Votes: `}</div>
                            <div className="row-addition-text2">{data.candidateVote}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied Accounts: `}</div>
                            <div className="row-addition-text2">{data.proxiesAccount}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Rewards: `}</div>
                            <div className="row-addition-text2">{data.rewards}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxy Fee: `}</div>
                            <div className="row-addition-text2">{data.proxyFee}</div>
                        </div>
                        {
                            (data.website && data.website !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Website: `}</div>
                                    <a href={data.website} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.website)}</a></div>
                                : null
                        }
                        {
                            (data.steemit && data.steemit !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Steemit: `}</div>
                                    <a href={`https://steemit.com/@${data.steemit}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.steemit)}</a></div>
                                : null
                        }
                        {
                            (data.telegram && data.telegram !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Telegram: `}</div>
                                    <a href={`https://t.me/${data.telegram}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.telegram)}</a></div>
                                : null
                        }
                        {
                            (data.twitter && data.twitter !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Twitter: `}</div>
                                    <a href={`https://twitter.com/${data.twitter}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.twitter)}</a></div>
                                : null
                        }
                    </td>
                    <td className="row-addition2" colspan="3">
                        <div className="row-addition-text title">Voting Philosophy</div>
                        <div className="row-addition-text paragraph">
                            {
                                data.votePhilosophy ? data.votePhilosophy.split('\n').map((items, index) => {
                                    return (
                                        <p className="paragraph-text" key={index}>{items}</p>
                                    )
                                }) : null
                            }
                        </div>
                        {/* <div className="row-addition-text title">Background</div>
                        <div className="row-addition-text paragraph">
                            {
                                data.backgroud ? data.backgroud.split('\n').map((items, index) => {
                                    return (
                                        <p className="paragraph-text" key={index}>{items}</p>
                                    )
                                }) : null
                            }
                        </div> */}
                    </td>
                    <td className="row-addition4">
                        <div className="row-addition-text title">Estimated Revenue</div>
                        <div className="row-addition-text paragraph">
                            <NumberFormat thousandSeparator={true} type="text" className="text-field w-input main-page" suffix={' EOS'} maxLength={15} onInput={this.maxLengthCheck}
                                value={this.state.isShowMoreInformation[index].EOSAmount || ''} onValueChange={this.updateEOSAmount(index, data.APR)} placeholder={"Enter EOS Amount"} />
                        </div>
                        <div className="row-addition-text paragraph apr">Daily: {this.state.isShowMoreInformation[index].EOSDaily} EOS</div>
                        <div className="row-addition-text paragraph apr">Annually: {this.state.isShowMoreInformation[index].EOSAnnually} EOS</div>
                    </td>
                </tr>
            ]
        }) : null

        var ExchangeProxyTable = this.state.exchangeProxyTable ? this.state.exchangeProxyTable.map((data, index) => {
            return [
                <tr key={index} className="row100-body">
                    <td className="column2">
                        <div className="column-text">{index + 1}</div>
                    </td>
                    <td className="column3 exchange">
                        <img src={data.logo_256} width={32} height={32} alt="" className="image-12 exchange" />
                        <div className="column-text name">{data.name}</div>
                    </td>
                    <td className="column4 exchange">
                        <div className="column-text">{data.stakeEOS.toLocaleString('en')}</div>
                    </td>
                    <td className="column5 exchange">
                        <div className="column-text">{data.frequency}</div>
                    </td>
                    <td className="column6 exchange">
                        <div className="column-text">{data.rewards}</div>
                    </td>
                </tr>,
                <tr key={-index} className={`row-addition ${this.state.isShowMoreInformationEx[index].isShow ? '' : 'hide'}`}>
                    <td className="row-addition1" colspan="6">
                        <div className="row-addition-text title">Details</div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Account: `}</div>
                            <div className="row-addition-text2">{data.name}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied EOS: `}</div>
                            <div className="row-addition-text2">{parseFloat(data.stakeEOS).toLocaleString('en')}</div></div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Candidate Votes: `}</div>
                            <div className="row-addition-text2">{data.candidateVote}</div>
                        </div>
                        <div className="row-addition-text">
                            <div className="row-addition-text1">{`Proxied Accounts: `}</div>
                            <div className="row-addition-text2">{data.proxiesAccount}</div>
                        </div>
                        {
                            (data.website && data.website !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Website: `}</div>
                                    <a href={data.website} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.website)}</a></div>
                                : null
                        }
                        {
                            (data.steemit && data.steemit !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Steemit: `}</div>
                                    <a href={`https://steemit.com/@${data.steemit}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.steemit)}</a></div>
                                : null
                        }
                        {
                            (data.telegram && data.telegram !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Telegram: `}</div>
                                    <a href={`https://t.me/${data.telegram}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.telegram)}</a></div>
                                : null
                        }
                        {
                            (data.twitter && data.twitter !== '') ?
                                <div className="row-addition-text">
                                    <div className="row-addition-text1">{`Twitter: `}</div>
                                    <a href={`https://twitter.com/${data.twitter}`} rel="noopener noreferrer" target="_blank" className="row-addition-text2 link">{this.shortString(data.twitter)}</a></div>
                                : null
                        }
                    </td>
                </tr>
            ]
        }) : null

        return (
            <div className="body">
                {
                    this.state.IsLoading ? <Spin /> : null
                }

                <div className="main-content">
                    <div className="div-block-5">
                        <div className="text-block-13 sub3" onClick={this.handleVoteProxy()}>
                            <CountUp duration={2} end={this.state.totalEOS} separator=","/> {strings["EOSvote"]}
                        </div>
                        <h1 className="heading-5" >{strings["EOSreturn"]}</h1>
                        <div className="text-block-13" >
                            {strings["EOSVoteRewards"]}
                        </div>
                        <div className="button-3 w-button-edit" onClick={this.handleVoteEarn}>{strings["VoteAndEarn"]}</div>
                    </div>
                    <a className="div-block-5" href="https://www.youtube.com/watch?v=s_H4Onwu2-A&feature=youtu.be" rel="noopener noreferrer" target="_blank" >
                        <img src={EOS_Main100} alt="" className="image-6" />
                    </a>
                </div>
                {/* <div className="main-content-infor">
                    <div className="main-content-infor-1">
                        <div className="main-content-infor-2"><CountUp duration={2} end={this.state.eosTotalReward} separator=","/> EOS</div>
                        <div className="main-content-infor-3">{strings["Total EOS Reward"]}</div>
                    </div>
                    <div className="main-content-infor-1">
                        <div className="main-content-infor-2"><CountUp duration={2} end={this.state.genBurn}  separator=","/> GEN</div>
                        <div className="main-content-infor-3">{strings["Total GEN Burned"]}</div>
                    </div>
                    <div className="main-content-infor-1">
                        <div className={`main-content-infor-2 ${this.state.isComingDays ? 'red' : ''}`}><CountUp duration={2} end={this.state.incomingDays}  separator=","/> Days</div>
                        <div className="main-content-infor-3">{strings["Countdown to Next Burn Event"]}</div>
                    </div>

                </div> */}
                <div className="main-content">
                    <iframe className="main-content-iframe" src="https://www.youtube.com/embed/s_H4Onwu2-A" title="genpool.io"
                        frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
                <div className="section-3">
                    <div className="div-block-9">
                        <h2 className="heading-5 sub">{strings["DailyReward"]}</h2>
                    </div>
                    <div className="div-block-8">
                        <div className="div-block-7"><img src={mJafI} alt="" /></div>
                        <div className="div-block-7"><img src={msbg3} alt="" /></div>
                        <div className="div-block-7"><img src={mYxkZ} alt="" /></div>
                        <div className="div-block-7"><img src={mi5Pz} alt="" /></div>
                        <div className="div-block-7"><img src={Logo_06nJ} alt="" /></div>
                        <div className="div-block-7"><img src={mkDyx} alt="" /></div>
                        <div className="div-block-7 h1"><img src={Logo_2Mk3} alt="" /></div>
                        <div className="div-block-7"><img src={Cmvk} alt="" /></div>
                        <div className="div-block-7"><img src={Logo_2Gv6} alt="" /></div>
                        <div className="div-block-7 h1"><img src={lvAW} alt="" /></div>
                        <div className="div-block-7 h1"><img src={m76HF} alt="" /></div>
                        <div className="div-block-7 h"><img src={mitVk} alt="" /></div>
                        <div className="div-block-7 h"><img src={mteG8} alt="" /></div>
                        <div className="div-block-7 h"><img src={mc4Hb} alt="" /></div>
                        <div className="div-block-7 h"><img src={m3doB} alt="" /></div>
                    </div>
                    <div className="div-block-10">
                        <div className="text-block-13 centre">
                            {strings["Introduce"]}
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    <div id="Hero" className="hero">
                        <div>
                            <div className="hero-heading">GENPOOL</div>
                            <div className="hero-subheading">THE&nbsp;FIRST&nbsp;EOS&nbsp;VOTER&nbsp;EXCHANGE</div>
                        </div>
                    </div>
                </div>
                <div ref={(ref) => this.myRef = ref} id="RewardProxy">
                    <div className="div-block-36">
                        <div className="div-block-37">
                            <h1 className="heading-5-pickreward">{strings["PickRewards"]}</h1>
                        </div>
                        <div className="div-block-53">
                            <a href="https://genpool.zendesk.com/hc/en-us/articles/360045817031-GenPool-io-Getting-Started-Guide"
                                target="_blank" rel="noopener noreferrer" className="button-3-copy w-button-edit">{strings["HowToGuide"]}</a>
                        </div>
                    </div>

                    <div className="div-block-50">
                        <Link to={PUBLIC_PATH} className={`div-block-51 ${this.state.tabId === PUBLIC ? 'active' : ''}`} onClick={this.selectTab(PUBLIC)}>
                            <div className="div-block-52">{strings["tab_public"]}</div>
                        </Link>
                        <Link to={EXCHANGE_PATH} className={`div-block-51 ${this.state.tabId === EXCHANGE ? 'active' : ''}`} onClick={this.selectTab(EXCHANGE)}>
                            <div className="div-block-52">{strings["tab_exchange"]}</div>
                        </Link>
                    </div>
                    {
                        this.state.tabId === PUBLIC ?
                            <div className="home-table">
                                <div className="container-table100">
                                    <div className="table100">
                                        <table>
                                            <thead>
                                                <tr className="row100-head">
                                                    <th className="column2">
                                                        <div className="col-text">{strings["Rank"]}</div>
                                                    </th>
                                                    <th className="column3">
                                                        <div className="col-text">{strings["Name"]}</div>
                                                    </th>
                                                    <th className="column4">
                                                        <div className="col-text">{strings["StakedEOS"]}</div>
                                                    </th>
                                                    <th className="column5">
                                                        <span className="tooltiptext-main-column5">{strings["tooltip_fre"]}</span>
                                                        <div className="col-text">{strings["Frequency"]}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    <th className="column8">
                                                        <span className="tooltiptext-main-column8">{strings["tooltip_apr"]}</span>
                                                        <div className="col-text">{strings['apr']}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    {/* <th className="column8"> */}
                                                    {/* <div className="col-text">+REX%</div> */}
                                                    {/* </th> */}
                                                    <th className="column9">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {proxyTable}

                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        this.state.IsloadingTable ?
                                            <div className="rowrewarddiv-loading">
                                                <img src={spin} width={100} alt="" className="image-12" />
                                            </div> : null
                                    }
                                </div>
                            </div> : null
                    }
                    {
                        this.state.tabId === EXCHANGE ?
                            <div className="home-table exchange">
                                <div className="container-table100 exchange">
                                    <div className="table100">
                                        <table>
                                            <thead>
                                                <tr className="row100-head">
                                                    <th className="column2">
                                                        <div className="col-text">{strings["Rank"]}</div>
                                                    </th>
                                                    <th className="column3 exchange">
                                                        <div className="col-text">{strings["Name"]}</div>
                                                    </th>
                                                    <th className="column4 exchange">
                                                        <div className="col-text">{strings["StakedEOS"]}</div>
                                                    </th>
                                                    <th className="column5 exchange">
                                                        <span className="tooltiptext-main-column5">{strings["tooltip_fre"]}</span>
                                                        <div className="col-text">{strings["Frequency"]}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    <th className="column6 exchange">
                                                        <span className="tooltiptext-main-column6">{strings["tooltip_reward"]}</span>
                                                        <div className="col-text">{strings["Rewards"]}<img src={info_topup} className="img-info-topup" alt="" /></div>
                                                    </th>
                                                    <th className="column9 exchange">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {ExchangeProxyTable}

                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        this.state.IsloadingTable ?
                                            <div className="rowrewarddiv-loading">
                                                <img src={spin} width={100} alt="" className="image-12" />
                                            </div> : null
                                    }
                                </div>
                            </div> : null
                    }
                    <div className="div-block-15" />
                </div>
                {/* define gradient color for rechart */}
                <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={0} stop-color="rgba(44, 186, 224,0.6)" stop-opacity={1} />
                        <stop offset={1} stop-color="rgba(44, 186, 224,0.01)" stop-opacity={1} />
                    </linearGradient>
                </svg>
                <Footer />

            </div>
        );
    }
}

// Map all state to component props (for redux to connect)
const mapStateToProps = state => {
    return {
        wallet: state.wallet,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        indetify: () => {
            IdentityWallet(dispatch);
        },
        login: () => {
            LoginWallet(dispatch);
        },
        logout: () => {
            LogoutWallet(dispatch);
        }
    };
};

// Export a redux connected component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(MainPage))
