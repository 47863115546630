// React core
import React, { Component } from 'react';

import { Footer } from 'components';

class BpPortal extends Component {
    render() {
        return (
            <div className="body">
                <div className="full-container">
                    <h1 className="h1">List Proxy</h1>
                    <p className="paragraph-3">List and customise your very own rewards proxy.</p>
                    <div data-duration-in={300} data-duration-out={100} className="tabs w-tabs">
                        <div className="tabs-menu w-clearfix w-tab-menu">
                            <div data-w-tab="Tab 4" className="tab w-inline-block w-tab-link w--current">
                                <div>LIST&nbsp;PROXY</div>
                            </div>
                        </div>
                        <div className="tabs-content w-tab-content">
                            <div data-w-tab="Tab 4" className="tab-pane-table w-tab-pane w--tab-active">
                                <div className="table-div">
                                    <div className="panel w-clearfix">
                                        <div className="_50-copy">
                                            <div className="w-form">
                                                <form id="email-form" name="email-form" data-name="Email Form" className="form">
                                                    <label htmlFor="name" className="lable1">Proxy Account Name:</label>
                                                    <input type="text" id="name" name="name" data-name="Name" required maxLength={256} placeholder="EOS Account Name" className="text2 w-input" />
                                                    <label htmlFor="name-2" className="lable1">Vpay% to voters</label>
                                                    <input type="text" id="name-2" name="name-2" data-name="Name 2" required maxLength={256} placeholder="0-98% - 80% Recommended " className="text2 w-input" />
                                                    <label htmlFor="name-3" className="lable1">Proxy Fee%</label>
                                                    <input type="text" id="name-2" name="name-2" data-name="Name 2" required maxLength={256} placeholder="0-98% - 0% Recommended" className="text2 w-input" />
                                                    <input type="submit" defaultValue="SUBMIT " data-wait="Please wait..." className="button-copy-copy w-button-edit" />
                                                    <div className="text-block-12-copy">
                                                        <em className="italic-text">Note: Vpay and Proxy fee must not add up to greater than 98% in total. The service fee is 2%.</em>
                                                    </div>
                                                </form>
                                                <div className="w-form-done">
                                                    <p>Thank you! Your registration has been received!</p>
                                                </div>
                                                <div className="w-form-fail">
                                                    <p>Oops! Something went wrong while submitting the form</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="_50" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

// Export a redux connected component
export default (BpPortal);
