// React core
import React, { Component } from 'react';

import { Footer } from 'components';

class BpInternalAction extends Component {
    render() {
        return (
            <div className="body">
                <div className="section-6">
                    <div className="div-block-19">
                        <div id="RewardProxy" className="div-block-11-copy">
                            <div className="div-block-14">
                                <div className="actionsdiv">
                                    <h2 className="heading-5 sub2">Set Daily Donation Amount</h2>
                                    <div className="form-block-2 w-form">
                                        <form id="email-form" name="email-form" data-name="Email Form"><input type="text" className="text-field w-input" maxLength={256} name="name-2" data-name="Name 2" placeholder={1234} id="name-2" /></form>
                                        <div className="w-form-done">
                                            <div>Thank you! Your registration has been received!</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                    </div>
                                    <div className="text-block-13 info">If you would like to donate extra per day to increase the proxy APR% </div>
                                    <div className="button4 w-button-edit">Donate</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

// Export a redux connected component
export default (BpInternalAction);
