import { createStore } from 'redux';
import rootReducer from 'services/Reducer';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore
  }
};


const state = loadState();
const store = createStore(rootReducer, state);
store.subscribe(() => {
  saveState({
    wallet: store.getState().wallet,
  });
});

export default store;
